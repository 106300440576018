export default {
  coordinatesData: state => state.coordinatesData,
  inputData: state => state.inputData,
  language: state => state.language,
  loadingScreen: state => state.loadingScreen,
  relations: state => state.relations,
  selectedItem: state => state.selectedItem,
  selectedStartDate: state => state.selectedStartDate,
  selectedEndDate: state => state.selectedEndDate,
  selectedCoordinatesType: state => state.selectedCoordinatesType,
  theme: state => state.theme,
  year: state => state.year
};
