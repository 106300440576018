<template>
  <ul class="flexi-desktop-navigation__wrapper">
    <slot name="navigation" v-bind="{ navigation }">
      <a
        class="flexi-desktop-navigation__wrapper--item"
        v-for="(navigationItem, index) in navigation"
        :key="'item-' + index"
        @click="
          navigationItem.isLink
            ? navigateToExternalPage(navigationItem.href)
            : navigateToRoute(navigationItem.href)
        "
      >
        <slot name="navigation-item" v-bind="{ navigationItem }">
          <li v-text="navigationItem.name" />
        </slot>
      </a>
    </slot>
  </ul>
</template>
<script>
export default {
  props: {
    navigation: {
      type: Array
    },
    navigateToExternalPage: {
      type: Function
    },
    navigateToRoute: {
      type: Function
    }
  }
};
</script>
