export default {
  setCMSData(state, data) {
    state.inputData = data;
  },
  setAPIData(state, data) {
    state.coordinatesData = data;
    setTimeout(() => {
      state.loadingScreen = false;
    }, 1500);
  },
  setYear(state) {
    state.year = new Date().getFullYear();
  },
  setRelations(state, data) {
    state.relations = data;
  },
  setTheme: state => {
    document.documentElement.setAttribute("data-theme", state.theme);
  },
  toggleTheme: state => {
    state.theme === "darkTheme"
      ? (state.theme = "")
      : (state.theme = "darkTheme");
    document.documentElement.setAttribute("data-theme", state.theme);
  },
  setLanguage(state, language) {
    state.language = language;
  },
  setSelectedItem(state, item) {
    state.selectedItem = item;
  },
  setSelectedStartDate(state, date) {
    state.selectedStartDate = date;
  },
  setSelectedEndDate(state, date) {
    state.selectedEndDate = date;
  },
  setSelectedCoordinatesType(state, type) {
    state.selectedCoordinatesType = type;
  }
};
