import Vue from "vue";
import emailjs from "emailjs-com";

Vue.mixin({
  watch: {
    currentResolution: {
      handler(newVal) {
        newVal <= 990 ? (this.isMobile = true) : (this.isMobile = false);
      },
      immediate: true
    }
  },
  data() {
    return {
      currentResolution: window.innerWidth,
      isMobile: false,
      loading: false,
      modal: null,
      modalArrayData: [],
      currentModalId: null,
      contactForm: {
        subject: null,
        name: null,
        email: null,
        phone: null,
        message: null
      },
      newsForm: {
        firstName: null,
        lastName: null,
        email: null
      }
    };
  },
  methods: {
    getCurrentResolution() {
      this.currentResolution = window.innerWidth;
    },
    openModal(data, array, id, index) {
      var self = this;
      self.modalArrayData = array;
      self.modal = data;
      self.currentModalId = index;
      location.href = "#services";
    },
    goToRoute(route) {
      this.$router.push(route).catch(err => {
        err;
      });
    },
    goToRouteFromObject(data) {
      this.$router.push(data.buttonLink);
    },
    smoothScroll(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    sendContactEmail(form) {
      var self = this;
      self.contactForm = form;
      self.loading = true;
      emailjs
        .send(
          "mailjet",
          "contact",
          {
            from_name: this.contactForm.email,
            to_name: "contact@akrinum.com",
            subject: this.contactForm.subject,
            name: this.contactForm.name,
            phone: this.contactForm.phone,
            message_html: this.contactForm.message
          },
          process.env.VUE_APP_MAIL_USER_ID
        )
        .then(
          function() {
            self.loading = false;
          },
          function() {
            self.loading = false;
          }
        );
      this.resetContactForm();
    },
    sendNewsEmail(form) {
      var self = this;
      self.newsForm = form;
      self.loading = true;
      emailjs
        .send(
          "mailjet",
          "template_IlJehvBQ",
          {
            from_name: this.newsForm.email,
            to_name: "news@akrinum.com",
            message_html: this.newsForm.firstName,
            name: this.newsForm.lastName
          },
          process.env.VUE_APP_MAIL_USER_ID
        )
        .then(
          function() {
            self.loading = false;
          },
          function() {
            self.loading = false;
          }
        );
      this.resetNewsForm();
    },
    resetContactForm() {
      this.contactForm.subject = null;
      this.contactForm.name = null;
      this.contactForm.email = null;
      this.contactForm.phone = null;
      this.contactForm.message = null;
    },
    resetNewsForm() {
      this.newsForm.firstName = null;
      this.newsForm.lastName = null;
      this.newsForm.email = null;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.getCurrentResolution);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getCurrentResolution);
  }
});
