var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flexi-mobile-navigation__wrapper"},[_vm._t("burger",function(){return [_c('div',{staticClass:"flexi-mobile-navigation__wrapper--navigation"},[_c('button',{staticClass:"burger__button",class:{ active: _vm.isNavOpen },on:{"click":_vm.toggleBurger}},[_c('span',{staticClass:"burger__button--bar"}),_c('span',{staticClass:"burger__button--bar"}),_c('span',{staticClass:"burger__button--bar"})])])]},null,{ isNavOpen: _vm.isNavOpen, toggleBurger: _vm.toggleBurger }),_vm._t("sidebar",function(){return [(_vm.isNavOpen)?_c('div',{staticClass:"flexi-mobile-navigation__wrapper--sidebar"},[_c('div',{staticClass:"logo__wrapper"},[_c('img',{staticClass:"logo",attrs:{"src":"https://app.imgforce.com/images/user/zrC_1622176244_logo-black-120.png"},on:{"click":function($event){return _vm.navigateToRoute('/')}}})]),_c('ul',{staticClass:"sidebar__wrapper"},_vm._l((_vm.navigation),function(navigationItem,index){return _c('a',{key:'item-' + index,staticClass:"sidebar__wrapper--item",on:{"click":function($event){navigationItem.isLink
              ? _vm.navigateToExternalPage(navigationItem.href)
              : _vm.navigateToRoute(navigationItem.href)}}},[_vm._t("sidebar-item",function(){return [_c('li',{domProps:{"textContent":_vm._s(navigationItem.name)}})]},null,{ navigationItem: navigationItem, navigation: _vm.navigation })],2)}),0),_c('div',{staticClass:"button__wrapper"},[_vm._t("close-button",function(){return [_c('button',{staticClass:"button",domProps:{"textContent":_vm._s('Back')},on:{"click":_vm.toggleBurger}})]},null,{ navigation: _vm.navigation, toggleBurger: _vm.toggleBurger })],2)]):_vm._e()]},null,{
      navigation: _vm.navigation,
      isNavOpen: _vm.isNavOpen,
      navigateToRoute: _vm.navigateToRoute,
      navigateToExternalPage: _vm.navigateToExternalPage,
      toggleBurger: _vm.toggleBurger
    })],2)}
var staticRenderFns = []

export { render, staticRenderFns }