<template>
  <div class="flexi-container">
    <!-- Top Card  -->
    <flexi-top-card
      v-if="inputData.homePage"
      :inputData="inputData.homePage.top_card"
      @button-clicked="smoothScroll"
    >
      <template v-slot:content>
        <div class="container">
          <div class="content">
            <h1
              class="top-card__title"
              v-text="inputData.homePage.top_card.title"
            />
            <p class="top-card__description">
              {{ inputData.homePage.top_card.subTitle }}
              <br />
              {{ inputData.homePage.top_card.question }}
              <br />
              {{ inputData.homePage.top_card.quote }}
            </p>
          </div>
        </div>
        <canvas class="webgl"></canvas>
      </template>
    </flexi-top-card>

    <!-- Map -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[3]"
    >
      <template v-slot:header="slotProps">
        <h2 class="header__title">
          <span v-text="slotProps.inputData.title" />
        </h2>
      </template>
      <template v-slot:content="slotProps">
        <div class="selector-wrapper">
          <div class="selector-wrapper__item">
            <font-awesome-icon icon="map-marker-alt" size="1x" />
            <label class="title">{{ slotProps.inputData.subTitle }}</label>
          </div>
          <div class="selector-wrapper__item">
            <label class="title" v-text="slotProps.inputData.headerSubtitle" />
            <v-select
              v-model="typeFilter"
              :options="
                language === 'МК' || language === 'MK'
                  ? typeFilters
                  : '' || language === 'АЛ' || language === 'AL'
                  ? typeFiltersAL
                  : '' || language === 'АН' || language === 'EN'
                  ? typeFiltersEN
                  : ''
              "
              :reduce="name => name.name"
              label="name"
              class="chart-chooser"
            />
          </div>
          <div
            v-if="
              selectedCoordinatesType === 'Дозвола за користење на води' ||
                selectedCoordinatesType === 'Дозвола за испуштање на води' ||
                selectedCoordinatesType === 'Leje përdorimi i ujit' ||
                selectedCoordinatesType === 'Leja e shkarkimit të ujit'
            "
            class="selector-wrapper__item"
          >
            <label
              class="title"
              v-text="slotProps.inputData.headerButtonLink"
            />
            <v-select
              v-if="relations.recni_slivovi"
              v-model="selectedRecenSliv"
              :options="relations.recni_slivovi"
              :reduce="name => name.name"
              label="name"
              class="chart-chooser"
            />
          </div>
          <div class="selector-wrapper__item selector-wrapper__filter">
            <label
              class="title"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'Пребарувај'
                  : language === 'AL' || language === 'АЛ'
                  ? 'Kërko'
                  : 'Пребарувај'
              "
            />
            <input
              v-model="search"
              type="text"
              :placeHolder="
                language === 'MK' || language === 'МК'
                  ? 'Носител/Место'
                  : language === 'АЛ' || language === 'AL'
                  ? 'Bartës/Vendi'
                  : ''
              "
            />
          </div>
          <div class="selector-wrapper__item">
            <label
              class="title"
              v-text="slotProps.inputData.headerDescription"
            />
            <datepicker
              ref="dp3"
              v-model="selectedStartDate"
              :minimumView="'day'"
              :maximumView="'month'"
              :initialView="'month'"
              :format="'d MMMM yyyy'"
              :language="language === 'МК' || language === 'MK' ? mk : en"
            />
          </div>
          <div class="selector-wrapper__item">
            <label class="title">{{
              slotProps.inputData.headerButtonText
            }}</label>
            <datepicker
              ref="dp4"
              v-model="selectedEndDate"
              :minimumView="'day'"
              :maximumView="'month'"
              :initialView="'month'"
              :format="'d MMMM yyyy'"
              :language="language === 'МК' || language === 'MK' ? mk : en"
            />
          </div>
        </div>
        <div class="bar-chart">
          <div class="bar-chart__header">
            <div class="icon">
              <font-awesome-icon icon="chart-bar" size="1x" />
              <span class="station" v-text="typeFilter" />
              <span
                class="station"
                v-text="
                  language === 'MK' || language === 'МК'
                    ? 'Од: ' +
                      getFormatDate(selectedStartTableDate) +
                      ' | До: ' +
                      getFormatDate(selectedEndTableDate)
                    : '' || language === 'AL' || language === 'АЛ'
                    ? 'Nga/ dita muaji viti ' +
                      getFormatDate(selectedStartTableDate) +
                      ' | Deri më / dita muaji viti ' +
                      getFormatDate(selectedEndTableDate)
                    : ''
                "
              />
            </div>
          </div>
        </div>
        <div class="map__wrapper" id="map__wrapper">
          <l-map
            :center="mapOptions.center"
            :zoom="isMobile ? 7 : mapOptions.zoom"
          >
            <l-tile-layer
              :attribution="mapOptions.attribution"
              :url="mapOptions.url"
            />
            <l-marker
              v-for="(value, key) in coordinates"
              :key="key"
              :icon="getIcon(value)"
              :lat-lng="[
                value.lokacija_zafakanje.length > 0
                  ? value.lokacija_zafakanje[0].x
                  : null,
                value.lokacija_zafakanje.length > 0
                  ? value.lokacija_zafakanje[0].y
                  : null
              ]"
            >
              <l-popup>
                <div class="inside">
                  <strong
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Носител на дозвола ' +
                          value.ime_na_nositel_na_dozvola
                        : language === 'АЛ' || language === 'AL'
                        ? 'Bartës i lejes' + value.ime_na_nositel_na_dozvola
                        : ''
                    "
                  />
                  <div
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Број на дозвола: ' + value.broj_na_dozvola
                        : language === 'АЛ' || language === 'AL'
                        ? '-Numri i lejes:' + value.broj_na_dozvola
                        : ''
                    "
                  />
                  <div
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Катастарска општина: ' +
                          value.lokacija_zafakanje[0].ko
                        : language === 'АЛ' || language === 'AL'
                        ? '-Komuna kadastrale:' + value.lokacija_zafakanje[0].ko
                        : ''
                    "
                  />
                  <div
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Датум на издадена дозвола: ' +
                          getFormatDate(value.datum_na_izdadena_dozvola)
                        : language === 'АЛ' || language === 'AL'
                        ? '-Data e lëshimit të lejes:' +
                          getFormatDate(value.datum_na_izdadena_dozvola)
                        : ''
                    "
                  />
                  <div
                    v-if="value.vid_na_objektot_ispustanjes"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Вид на објектот за испуштање: ' +
                          value.vid_na_objektot_ispustanjes.name
                        : language === 'АЛ' || language === 'AL'
                        ? '-Lloji i objektit:' +
                          value.vid_na_objektot_ispustanjes.name
                        : ''
                    "
                  />
                  <div
                    v-if="value.vid_na_objektots"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Вид на објектот: ' + value.vid_na_objektots.name
                        : language === 'АЛ' || language === 'AL'
                        ? '-Përse përdoret uji:' + value.vid_na_objektots.name
                        : ''
                    "
                  />
                  <div v-if="value.namena_na_vodata_multis">
                    <div
                      style="font-weight: 600"
                      v-text="
                        language === 'MK' || language === 'МК'
                          ? '-Намена на водата:'
                          : language === 'АЛ' || language === 'AL'
                          ? '-Pellgu:'
                          : ''
                      "
                    />
                    <ul>
                      <li
                        v-for="(v, k) in value.namena_na_vodata_multis"
                        :key="k"
                      >
                        {{ v.namena_na_vodatas.name }}
                      </li>
                    </ul>
                  </div>
                  <div
                    v-if="value.naziv_na_povrsinsko_telo"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Вид на површинско тело: ' +
                          value.naziv_na_povrsinsko_telo
                        : language === 'АЛ' || language === 'AL'
                        ? '-Emri i trupit sipërfaqësor:' +
                          value.naziv_na_povrsinsko_telo
                        : ''
                    "
                  />
                  <div
                    v-if="value.vkupni_kolicini_pesok"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Вкупни количини кои треба да се извадат од речното корито: ' +
                          value.vkupni_kolicini_pesok +
                          ' m3'
                        : language === 'АЛ' || language === 'AL'
                        ? '-Sasitë totale që do të nxirren nga shtrati i lumit:' +
                          value.vkupni_kolicini_pesok +
                          ' m3'
                        : ''
                    "
                  />
                  <div
                    v-if="value.vkupni_kolicini_pesok_za_odrzuvanje"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Вкупни количини за одржување и изградба на заштитните водостопански објекти: ' +
                          value.vkupni_kolicini_pesok_za_odrzuvanje +
                          ' m3'
                        : language === 'АЛ' || language === 'AL'
                        ? '-Sasitë totale për mirëmbajtjen dhe ndërtimin e objekteve mbrojtëse të menaxhimit të ujit: ' +
                          value.vkupni_kolicini_pesok_za_odrzuvanje +
                          ' m3'
                        : ''
                    "
                  />
                  <div
                    v-if="value.vkupni_kolicini_pesok_za_promet"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Вкупни количини на материјал кои можат да се пуштат во промет: ' +
                          value.vkupni_kolicini_pesok_za_promet +
                          ' m3'
                        : language === 'АЛ' || language === 'AL'
                        ? '-Sasitë totale të materialit që mund të vendosen në treg: ' +
                          value.vkupni_kolicini_pesok_za_promet +
                          ' m3'
                        : ''
                    "
                  />
                  <div
                    v-if="value.recni_slivovis"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Слив: ' + value.recni_slivovis.name
                        : language === 'АЛ' || language === 'AL'
                        ? '-Pellgu lumor ' + value.recni_slivovis.name
                        : ''
                    "
                  />
                  <div
                    v-if="value.recipent"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Реципент: ' + value.recipent
                        : language === 'АЛ' || language === 'AL'
                        ? '-Marrësi:' + value.recipent
                        : ''
                    "
                  />
                  <div
                    v-if="value.kolicestvo_voda"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Одобрено количество вода: ' + value.kolicestvo_voda
                        : language === 'АЛ' || language === 'AL'
                        ? '-Sasia e miratuar e ujit: ' + value.kolicestvo_voda
                        : ''
                    "
                  />
                  <div
                    v-if="value.odobreno_kolicestvo_voda"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Одобрено количество вода: ' +
                          value.odobreno_kolicestvo_voda
                        : language === 'АЛ' || language === 'AL'
                        ? '-Sasia e miratuar e ujit: ' +
                          value.odobreno_kolicestvo_voda
                        : ''
                    "
                  />
                  <div
                    v-if="value.vaznost_na_dozvolata"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? '-Важност на дозволата: ' + value.vaznost_na_dozvolata
                        : language === 'АЛ' || language === 'AL'
                        ? '-Vlefshmëria e lejes: ' + value.vaznost_na_dozvolata
                        : ''
                    "
                  />
                </div>
              </l-popup>
            </l-marker>
          </l-map>
        </div>
        <div class="legend__wrapper">
          <h3
            class="legend__wrapper--title"
            @click="toggleLegend"
            v-text="
              language === 'MK' || language === 'МК'
                ? 'ЛЕГЕНДА'
                : language === 'AL' || language === 'АЛ'
                ? 'LEGJENDË'
                : 'ЛЕГЕНДА'
            "
          />
          <div id="legend-popup" class="legend-popup">
            <a @click="toggleLegend" class="close" v-text="'X'" />
            <h3 class="legend-popup__title">
              {{ inputData.homePage.sections[0].title }}
            </h3>
            <div class="legend-popup-parent">
              <div class="legend__wr">
                <div class="legend__wr--item">
                  <div class="box" />
                  <div
                    class="title"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Дозволи за користење на води'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Zgjidh tabelën'
                        : 'Дозволи за користење на води'
                    "
                  />
                </div>
                <div class="legend__wr--item">
                  <div class="angle" />
                  <div
                    class="title"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Дозволи за испуштање на води'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Leje për lëshimin e ujërave'
                        : 'Дозволи за користење на води'
                    "
                  />
                </div>
              </div>
              <section
                class="legend-popup__our-clients"
                v-for="(value, key) in inputData.homePage.sections[0].widgets"
                :key="key"
              >
                <img
                  class="legend-popup__our-clients--logo"
                  :src="value.img"
                  alt="logo"
                />
                <span
                  class="legend-popup__our-clients--text"
                  v-html="value.paragraph"
                />
              </section>
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Datoteka / Tables -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[4]"
    >
      <template v-slot:header="slotProps">
        <h2 class="header__title">
          <span v-text="slotProps.inputData.title" />
        </h2>
      </template>
      <template v-slot:content="slotProps">
        <div class="selector-wrapper selector-wrapper__header">
          <div class="left">
            <div class="selector-wrapper__item">
              <font-awesome-icon icon="table" size="1x" />
              <label
                class="title"
                v-text="
                  language === 'MK' || language === 'МК'
                    ? 'Избери табела'
                    : language === 'AL' || language === 'АЛ'
                    ? 'Zgjidh tabelën'
                    : 'Избери табела'
                "
              />
            </div>
          </div>
          <div class="right">
            <div
              class="selector-wrapper__button"
              :class="{
                active: selectedTable === 'Дозволи за користење на води'
              }"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'Дозволи за користење на води'
                  : language === 'AL' || language === 'АЛ'
                  ? 'LEJE PËR PËRDORIMIN E UJIT'
                  : 'Дозволи за користење на води'
              "
              @click="changeSelectedTable('Дозволи за користење на води')"
            />
            <div
              class="selector-wrapper__button"
              :class="{
                active: selectedTable === 'Дозволи за испуштање на води'
              }"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'Дозволи за испуштање на води'
                  : language === 'AL' || language === 'АЛ'
                  ? 'LEJE PËR LËSHIMIN E UJËRAVE'
                  : 'Дозволи за испуштање на води'
              "
              @click="changeSelectedTable('Дозволи за испуштање на води')"
            />
            <div
              class="selector-wrapper__button"
              :class="{
                active: selectedTable === 'Дозволи за уредување на речни корита'
              }"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'Дозволи за уредување на речни корита'
                  : language === 'AL' || language === 'АЛ'
                  ? 'LEJE PËR RREGULLIMIN E SHTRETËRVE TË LUMENJVE'
                  : 'Дозволи за уредување на речни корита'
              "
              @click="
                changeSelectedTable('Дозволи за уредување на речни корита')
              "
            />
            <div
              class="selector-wrapper__button"
              :class="{
                active: selectedTable === 'Концесија за користење на води'
              }"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'Концесија за користење на води'
                  : language === 'AL' || language === 'АЛ'
                  ? 'KONCESION PËR PËRDORIMIN E UJIT'
                  : 'Концесија за користење на води'
              "
              @click="changeSelectedTable('Концесија за користење на води')"
            />
            <div
              class="selector-wrapper__button"
              :class="{
                active: selectedTable === 'Водостопанска согласност'
              }"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'Водостопанска согласност'
                  : language === 'AL' || language === 'АЛ'
                  ? 'PËLQIM PËR EKONOMI  UJORE'
                  : 'Водостопанска согласност'
              "
              @click="changeSelectedTable('Водостопанска согласност')"
            />
            <div
              class="selector-wrapper__button"
              :class="{
                active: selectedTable === 'Уписник за заштитени зони'
              }"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'Уписник за заштитени зони'
                  : language === 'AL' || language === 'АЛ'
                  ? 'REGJISTËR I ZONAVE TË MBROJTURA'
                  : 'Уписник за заштитени зони'
              "
              @click="changeSelectedTable('Уписник за заштитени зони')"
            />
          </div>
        </div>
        <div class="selector-wrapper">
          <div class="selector-wrapper__item">
            <font-awesome-icon icon="map-marker-alt" size="1x" />
            <label class="title">{{ slotProps.inputData.subTitle }}</label>
          </div>
          <div
            v-if="selectedTable !== 'Дозволи за уредување на речни корита'"
            class="selector-wrapper__item"
          >
            <label class="title" v-text="slotProps.inputData.headerSubtitle" />
            <v-select
              v-if="relations.recni_slivovi"
              v-model="selectedRecenSliv"
              :options="relations.recni_slivovi"
              :reduce="name => name.name"
              label="name"
              class="chart-chooser"
            />
          </div>
          <div class="selector-wrapper__item selector-wrapper__filter">
            <label
              class="title"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'Пребарувај'
                  : language === 'AL' || language === 'АЛ'
                  ? 'Kërko'
                  : 'Пребарувај'
              "
            />
            <input
              v-model="search"
              type="text"
              :placeHolder="
                language === 'MK' || language === 'МК'
                  ? 'Носител/Место'
                  : language === 'АЛ' || language === 'AL'
                  ? 'Bartës/Vendi'
                  : ''
              "
            />
          </div>
          <div
            class="selector-wrapper__item"
            :style="
              selectedTable === 'Дозволи за уредување на речни корита'
                ? 'display: flex; flex-direction: column; align-items: center;'
                : ''
            "
          >
            <label
              class="title"
              v-text="slotProps.inputData.headerDescription"
            />
            <datepicker
              ref="dp1"
              v-model="selectedStartTableDate"
              :minimumView="'day'"
              :maximumView="'month'"
              :initialView="'month'"
              :format="'d MMMM yyyy'"
              :language="language === 'МК' || language === 'MK' ? mk : en"
            />
          </div>
          <div class="selector-wrapper__item">
            <label class="title">{{
              slotProps.inputData.headerButtonText
            }}</label>
            <datepicker
              ref="dp2"
              v-model="selectedEndTableDate"
              :minimumView="'day'"
              :maximumView="'month'"
              :initialView="'month'"
              :format="'d MMMM yyyy'"
              :language="language === 'МК' || language === 'MK' ? mk : en"
            />
          </div>
        </div>
        <div class="bar-chart">
          <div class="bar-chart__header">
            <div class="icon">
              <font-awesome-icon icon="chart-bar" size="1x" />
              <span class="station" v-text="selectedTable" />
              <span
                v-if="selectedTable !== 'Дозволи за уредување на речни корита'"
                class="station"
                v-text="selectedRecenSliv"
              />
              <span
                class="station"
                v-text="
                  language === 'MK' || language === 'МК'
                    ? 'Од: ' +
                      getFormatDate(selectedStartTableDate) +
                      ' | До: ' +
                      getFormatDate(selectedEndTableDate)
                    : language === 'AL' || language === 'АЛ'
                    ? 'Nga/ dita muaji viti: ' +
                      getFormatDate(selectedStartTableDate) +
                      ' | Deri më / dita muaji viti: ' +
                      getFormatDate(selectedEndTableDate)
                    : ''
                "
              />
            </div>
          </div>
          <div
            v-if="selectedTable === 'Дозволи за користење на води'"
            class="bar-chart__content"
          >
            <table class="flexi-table">
              <tr v-if="language === 'МК' || language === 'MK'">
                <th v-text="'Носител на дозвола'" />
                <th v-text="'Број на дозвола'" />
                <th v-text="'Катастарска општина'" />
                <th v-text="'Датум на издадена дозвола'" />
                <th v-text="'Вид на објектот'" />
                <th v-text="'Намена на водата'" />
                <th v-text="'Слив'" />
                <th v-text="'Одобрено количество вода'" />
                <th v-text="'Важност на дозволата'" />
              </tr>

              <tr v-if="language === 'АЛ' || language === 'AL'">
                <th v-text="'Bartës i lejes'" />
                <th v-text="'Numri i lejes'" />
                <th v-text="'Komuna kadastrale'" />
                <th v-text="'Data e lëshimit të lejes'" />
                <th v-text="'Lloji i objektit'" />
                <th v-text="'Përse përdoret uji'" />
                <th v-text="'Pellgu'" />
                <th v-text="'Sasia e lejuar e ujit'" />
                <th v-text="'Vlefshmëria e lejes'" />
              </tr>

              <tr v-for="(value, key) in tableResult" :key="key">
                <td v-text="value.ime_na_nositel_na_dozvola" />
                <td v-text="value.broj_na_dozvola" />
                <td v-text="value.lokacija_zafakanje[0].ko" />
                <td v-text="getFormatDate(value.datum_na_izdadena_dozvola)" />
                <td>
                  <span
                    v-if="value.vid_na_objektots"
                    v-text="value.vid_na_objektots.name"
                  />
                  <span v-else v-text="'/'" />
                </td>
                <td>
                  <ul v-if="value.namena_na_vodata_multis">
                    <li
                      v-for="(v, k) in value.namena_na_vodata_multis"
                      :key="k"
                    >
                      {{ v.namena_na_vodatas.name }}
                    </li>
                  </ul>
                  <span v-else> /</span>
                </td>
                <td>
                  <span
                    v-if="value.recni_slivovis"
                    v-text="value.recni_slivovis.name"
                  />
                  <span v-else v-text="'/'" />
                </td>
                <td v-text="value.odobreno_kolicestvo_voda" />
                <td v-text="value.vaznost_na_dozvolata" />
              </tr>
            </table>

            <div class="table-pagination">
              <ve-pagination
                :total="totalCount"
                :page-index="pageIndex"
                :page-size="pageSize"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </div>
          <div
            v-else-if="selectedTable === 'Дозволи за испуштање на води'"
            class="bar-chart__content"
          >
            <table class="flexi-table">
              <tr v-if="language === 'МК' || language === 'MK'">
                <th v-text="'Носител на дозвола'" />
                <th v-text="'Број на дозвола'" />
                <th v-text="'Катастарска општина'" />
                <th v-text="'Датум на издадена дозвола'" />
                <th v-text="'Вид на објектот за испуштање'" />
                <th v-text="'Слив'" />
                <th v-text="'Реципент'" />
                <th v-text="'Одобрено количество вода'" />
                <th v-text="'Важност на дозволата'" />
              </tr>

              <tr v-else-if="language === 'АЛ' || language === 'AL'">
                <th v-text="'Bartës i lejes	'" />
                <th v-text="'Numri i lejes	'" />
                <th v-text="'Komuna kadastrale	'" />
                <th v-text="'Data e lëshimit të lejes	'" />
                <th v-text="'Lloji i objektit	'" />
                <th v-text="'Përse përdoret uji	'" />
                <th v-text="'Pellgu	'" />
                <th v-text="'Sasia e lejuar e ujit'" />
                <th v-text="'Vlefshmëria e lejes'" />
              </tr>

              <tr v-for="(value, key) in tableResult" :key="key">
                <td v-text="value.ime_na_nositel_na_dozvola" />
                <td v-text="value.broj_na_dozvola" />
                <td v-text="value.lokacija_ispustanje[0].ko" />
                <td v-text="getFormatDate(value.datum_na_izdadena_dozvola)" />
                <td>
                  <span
                    v-if="value.vid_na_objektot_ispustanjes"
                    v-text="value.vid_na_objektot_ispustanjes.name"
                  />
                  <span v-else v-text="'/'" />
                </td>
                <td>
                  <span
                    v-if="value.recni_slivovis"
                    v-text="value.recni_slivovis.name"
                  />
                  <span v-else v-text="'/'" />
                </td>
                <td v-text="value.recipent" />
                <td v-text="value.kolicestvo_voda" />
                <td v-text="value.vaznost_na_dozvolata" />
              </tr>
            </table>

            <div class="table-pagination">
              <ve-pagination
                :total="totalCount"
                :page-index="pageIndex"
                :page-size="pageSize"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </div>
          <div
            v-else-if="selectedTable === 'Дозволи за уредување на речни корита'"
            class="bar-chart__content"
          >
            <table class="flexi-table">
              <tr v-if="language === 'МK' || language === 'MK'">
                <th v-text="'Носител на дозвола'" />
                <th v-text="'Број на дозвола'" />
                <th v-text="'Катастарска општина'" />
                <th v-text="'Датум на издадена дозвола'" />
                <th v-text="'Вид на површинско тело'" />
                <th v-text="'Вк. количини кои треба да се извадат од корито'" />
                <th v-text="'Вк. количини за одржување и изградба'" />
                <th v-text="'Вк. количини на материјал во промет'" />
                <th v-text="'Важност на дозволата'" />
              </tr>

              <tr v-if="language === 'АЛ' || language === 'AL'">
                <th v-text="'Bartës i lejes'" />
                <th v-text="'Numri i lejes'" />
                <th v-text="'Komuna kadastrale'" />
                <th v-text="'Data e lëshimit të lejes'" />
                <th v-text="'Lloji i objektit	'" />
                <th v-text="'Sasitë totale që duhet të hiqen nga korti'" />
                <th v-text="'Sasitë totale për mirëmbajtje dhe ndërtim'" />
                <th v-text="'Sasitë totale të materialit në qarkullim'" />
                <th v-text="'Vlefshmëria e lejes'" />
              </tr>

              <tr v-for="(value, key) in tableResult" :key="key">
                <td v-text="value.ime_na_nositel_na_dozvola" />
                <td v-text="value.broj_na_dozvola" />
                <td v-text="value.lokacija_zafakanje[0].ko" />
                <td v-text="getFormatDate(value.datum_na_izdadena_dozvola)" />
                <td v-text="value.naziv_na_povrsinsko_telo" />
                <td v-text="value.vkupni_kolicini_pesok" />
                <td v-text="value.vkupni_kolicini_pesok_za_odrzuvanje" />
                <td v-text="value.vkupni_kolicini_pesok_za_promet" />
                <td v-text="value.vaznost_na_dozvolata" />
              </tr>
            </table>

            <div class="table-pagination">
              <ve-pagination
                :total="totalCount"
                :page-index="pageIndex"
                :page-size="pageSize"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </div>
          <div v-else class="bar-chart__content flex-center">
            <span
              class="no-data"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'Нема достапни податоци'
                  : language === 'АЛ' || language === 'AL'
                  ? 'Nuk ka të dhëna të disponueshme '
                  : ''
              "
            />
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- About -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[2]"
    >
      <template v-slot:header="slotProps">
        <h2 class="header__title">
          <span v-text="slotProps.inputData.title" />
        </h2>
        <div
          class="header__description"
          v-html="slotProps.inputData.description"
        />
      </template>
      <template v-slot:content>
        <div class="legend__wrapper">
          <div class="legend__wrapper--items">
            <div
              v-for="(value, key) in inputData.homePage.sections[5].widgets"
              class="flexi-column"
              :key="key"
            >
              <h3 class="flexi-column--title" v-text="value.title" />
              <div
                class="flexi-column--description"
                v-html="value.description"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Banners -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[6]"
    >
      <template v-slot:header="slotProps">
        <h2 class="header__title">
          <span v-text="slotProps.inputData.title" />
        </h2>
      </template>
      <template v-slot:content>
        <div
          v-for="(value, key) in inputData.homePage.sections[6].widgets"
          class="banner-image"
          :key="key"
        >
          <a v-if="key === 0" :href="value.buttonLink" target="_blank">
            <div class="header" v-text="value.title" />
            <div class="image__wrapper">
              <img :src="value.img" />
            </div>
          </a>
          <a v-else target="_blank">
            <div class="header" v-text="value.title" />
            <div class="image__wrapper">
              <img :src="value.img" />
            </div>
          </a>
        </div>
      </template>
    </flexi-wrapper>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { mk, al, en } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

import L from "leaflet";

import { gsap } from "gsap";
import CSSRulePlugin from "gsap/CSSRulePlugin";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as THREE from "three";
import * as dat from "dat.gui";

gsap.registerPlugin(CSSRulePlugin);

const coordinatesTypes = {
  KORISTENJE_VODI: "Дозвола за користење на води",
  ISPUSTANJE_VODI: "Дозвола за испуштање на води",
  PESOK_CAKAL: "Дозволи за уредување на речно корито",
  KONCESII: "Концесија за користење на води",
  VODOSTOPANSKI_SOGLASNOSTI: "Водостопанска согласност",
  ALL: "Сите податоци"
};

const coordinatesTypesAL = {
  KORISTENJE_VODI: "Leje përdorimi i ujit",
  ISPUSTANJE_VODI: "Leja e shkarkimit të ujit",
  PESOK_CAKAL: "Leje për nxjerrjen e rërës, zhavorrit dhe gurit",
  KONCESII: "Koncesioni për përdorimin e ujit",
  VODOSTOPANSKI_SOGLASNOSTI: "Marrëveshja e menaxhimit të ujit",
  ALL: "Të gjitha të dhënat"
};

const coordinatesTypesEN = {
  KORISTENJE_VODI: "Water use permit",
  ISPUSTANJE_VODI: "Water discharge permit",
  PESOK_CAKAL: "Permit for extraction of sand, gravel and stone",
  KONCESII: "Концесија за користење на води",
  VODOSTOPANSKI_SOGLASNOSTI: "Водостопанска согласност",
  ALL: "All data"
};

export default {
  name: "home",
  watch: {
    language() {
      if (this.language === "MK" || this.language === "МК") {
        this.typeFilter = coordinatesTypes.ALL;
      } else if (this.language === "AL" || this.language === "АЛ") {
        this.typeFilter = coordinatesTypesAL.ALL;
      } else {
        this.typeFilter = coordinatesTypesEN.ALL;
      }
    },
    selectedCoordinatesType: {
      handler(type) {
        this.typeFilter = type;
      }
    },
    selectedStartDate: {
      handler(date) {
        this.setSelectedStartDate(date);
      },
      immediate: true
    },
    selectedEndDate: {
      handler(date) {
        this.setSelectedEndDate(date);
      },
      immediate: true
    },
    typeFilter: {
      handler(filter) {
        if (this.language === "MK" || this.language === "МК") {
          if (filter === coordinatesTypes.ALL) {
            this.setSelectedItem([
              ...this.coordinatesData.koristenje_vodi,
              ...this.coordinatesData.ispustanje_vodi,
              ...this.coordinatesData.pesok_cakal_kamen
            ]);
          } else if (filter === coordinatesTypes.KORISTENJE_VODI) {
            this.setSelectedItem(this.coordinatesData.koristenje_vodi);
            this.setSelectedCoordinatesType(coordinatesTypes.KORISTENJE_VODI);
          } else if (filter === coordinatesTypes.ISPUSTANJE_VODI) {
            this.setSelectedItem(this.coordinatesData.ispustanje_vodi);
            this.setSelectedCoordinatesType(coordinatesTypes.ISPUSTANJE_VODI);
          } else if (filter === coordinatesTypes.PESOK_CAKAL) {
            this.setSelectedItem(this.coordinatesData.pesok_cakal_kamen);
            this.setSelectedCoordinatesType(coordinatesTypes.PESOK_CAKAL);
          } else {
            this.setSelectedItem("None");
            this.setSelectedCoordinatesType("None");
          }
        } else if (this.language === "AL" || this.language === "АЛ") {
          if (filter === coordinatesTypesAL.ALL) {
            this.setSelectedItem([
              ...this.coordinatesData.koristenje_vodi,
              ...this.coordinatesData.ispustanje_vodi,
              ...this.coordinatesData.pesok_cakal_kamen
            ]);
          } else if (filter === coordinatesTypesAL.KORISTENJE_VODI) {
            this.setSelectedItem(this.coordinatesData.koristenje_vodi);
            this.setSelectedCoordinatesType(coordinatesTypesAL.KORISTENJE_VODI);
          } else if (filter === coordinatesTypesAL.ISPUSTANJE_VODI) {
            this.setSelectedItem(this.coordinatesData.ispustanje_vodi);
            this.setSelectedCoordinatesType(coordinatesTypesAL.ISPUSTANJE_VODI);
          } else if (filter === coordinatesTypesAL.PESOK_CAKAL) {
            this.setSelectedItem(this.coordinatesData.pesok_cakal_kamen);
            this.setSelectedCoordinatesType(coordinatesTypesAL.PESOK_CAKAL);
          } else {
            this.setSelectedItem("None");
            this.setSelectedCoordinatesType("None");
          }
        } else {
          if (filter === coordinatesTypesEN.ALL) {
            this.setSelectedItem([
              ...this.coordinatesData.koristenje_vodi,
              ...this.coordinatesData.ispustanje_vodi,
              ...this.coordinatesData.pesok_cakal_kamen
            ]);
          } else if (filter === coordinatesTypesEN.KORISTENJE_VODI) {
            this.setSelectedItem(this.coordinatesData.koristenje_vodi);
            this.setSelectedCoordinatesType(coordinatesTypesEN.KORISTENJE_VODI);
          } else if (filter === coordinatesTypesEN.ISPUSTANJE_VODI) {
            this.setSelectedItem(this.coordinatesData.ispustanje_vodi);
            this.setSelectedCoordinatesType(coordinatesTypesEN.ISPUSTANJE_VODI);
          } else if (filter === coordinatesTypesEN.PESOK_CAKAL) {
            this.setSelectedItem(this.coordinatesData.pesok_cakal_kamen);
            this.setSelectedCoordinatesType(coordinatesTypesEN.PESOK_CAKAL);
          } else {
            this.setSelectedItem("None");
            this.setSelectedCoordinatesType("None");
          }
        }
      }
    }
  },
  components: {
    datepicker: Datepicker,
    vSelect
  },
  computed: {
    ...mapGetters([
      "coordinatesData",
      "inputData",
      "language",
      "relations",
      "selectedCoordinatesType",
      "selectedItem"
    ]),
    coordinates() {
      if (!this.selectedItem.length) {
        return [
          ...this.coordinatesData.koristenje_vodi,
          ...this.coordinatesData.ispustanje_vodi,
          ...this.coordinatesData.pesok_cakal_kamen
        ].filter(item => {
          return (
            (item.ime_na_nositel_na_dozvola
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
              item.broj_na_dozvola
                .toLowerCase()
                .includes(this.search.toLowerCase())) &&
            moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") >=
              moment(this.selectedStartDate).format("YYYY/MM/DD") &&
            moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") <=
              moment(this.selectedEndDate).format("YYYY/MM/DD")
          );
        });
      } else if (this.selectedCoordinatesType === "None") {
        return [];
      } else if (
        this.selectedCoordinatesType === "Дозвола за користење на води" ||
        this.selectedCoordinatesType === "Дозвола за испуштање на води" ||
        this.selectedCoordinatesType === "Leje përdorimi i ujit" ||
        this.selectedCoordinatesType === "Leja e shkarkimit të ujit"
      ) {
        return this.selectedItem.filter(item => {
          return (
            (item.ime_na_nositel_na_dozvola
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
              item.broj_na_dozvola
                .toLowerCase()
                .includes(this.search.toLowerCase())) &&
            moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") >=
              moment(this.selectedStartDate).format("YYYY/MM/DD") &&
            moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") <=
              moment(this.selectedEndDate).format("YYYY/MM/DD") &&
            item.recni_slivovis.name === this.selectedRecenSliv
          );
        });
      } else {
        return this.selectedItem.filter(item => {
          return (
            (item.ime_na_nositel_na_dozvola
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
              item.broj_na_dozvola
                .toLowerCase()
                .includes(this.search.toLowerCase())) &&
            moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") >=
              moment(this.selectedStartDate).format("YYYY/MM/DD") &&
            moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") <=
              moment(this.selectedEndDate).format("YYYY/MM/DD")
          );
        });
      }
    },
    tableResult() {
      const { pageIndex, pageSize } = this;

      if (this.selectedTable === "Дозволи за користење на води") {
        return this.coordinatesData.koristenje_vodi
          .filter(item => {
            return (
              item.ime_na_nositel_na_dozvola
                .toLowerCase()
                .includes(this.search.toLowerCase()) &&
              moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") >=
                moment(this.selectedStartTableDate).format("YYYY/MM/DD") &&
              moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") <=
                moment(this.selectedEndTableDate).format("YYYY/MM/DD") &&
              item.recni_slivovis.name === this.selectedRecenSliv
            );
          })
          .sort(
            (a, b) =>
              new Date(b.datum_na_izdadena_dozvola) -
              new Date(a.datum_na_izdadena_dozvola)
          )
          .slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
      } else if (this.selectedTable === "Дозволи за испуштање на води") {
        return this.coordinatesData.ispustanje_vodi
          .filter(item => {
            return (
              (item.ime_na_nositel_na_dozvola
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
                item.broj_na_dozvola
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) &&
              moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") >=
                moment(this.selectedStartTableDate).format("YYYY/MM/DD") &&
              moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") <=
                moment(this.selectedEndTableDate).format("YYYY/MM/DD") &&
              item.recni_slivovis.name === this.selectedRecenSliv
            );
          })
          .sort(
            (a, b) =>
              new Date(b.datum_na_izdadena_dozvola) -
              new Date(a.datum_na_izdadena_dozvola)
          )
          .slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
      } else if (
        this.selectedTable === "Дозволи за уредување на речни корита"
      ) {
        return this.coordinatesData.pesok_cakal_kamen
          .filter(item => {
            return (
              (item.ime_na_nositel_na_dozvola
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
                item.broj_na_dozvola
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) &&
              moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") >=
                moment(this.selectedStartTableDate).format("YYYY/MM/DD") &&
              moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") <=
                moment(this.selectedEndTableDate).format("YYYY/MM/DD")
            );
          })
          .sort(
            (a, b) =>
              new Date(b.datum_na_izdadena_dozvola) -
              new Date(a.datum_na_izdadena_dozvola)
          )
          .slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
      } else {
        return this.coordinatesData.koristenje_vodi
          .filter(item => {
            return (
              (item.ime_na_nositel_na_dozvola
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
                item.broj_na_dozvola
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) &&
              moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") >=
                moment(this.selectedStartTableDate).format("YYYY/MM/DD") &&
              moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") <=
                moment(this.selectedEndTableDate).format("YYYY/MM/DD")
            );
          })
          .slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
      }
    },
    totalCount() {
      if (this.coordinatesData) {
        if (this.selectedTable === "Дозволи за користење на води") {
          const totalData = this.coordinatesData.koristenje_vodi
            .filter(item => {
              return (
                item.ime_na_nositel_na_dozvola
                  .toLowerCase()
                  .includes(this.search.toLowerCase()) &&
                moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") >=
                  moment(this.selectedStartTableDate).format("YYYY/MM/DD") &&
                moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") <=
                  moment(this.selectedEndTableDate).format("YYYY/MM/DD") &&
                item.recni_slivovis.name === this.selectedRecenSliv
              );
            })
            .sort(
              (a, b) =>
                new Date(b.datum_na_izdadena_dozvola) -
                new Date(a.datum_na_izdadena_dozvola)
            );
          return totalData.length;
        } else if (this.selectedTable === "Дозволи за испуштање на води") {
          const totalData = this.coordinatesData.ispustanje_vodi
            .filter(item => {
              return (
                (item.ime_na_nositel_na_dozvola
                  .toLowerCase()
                  .includes(this.search.toLowerCase()) ||
                  item.broj_na_dozvola
                    .toLowerCase()
                    .includes(this.search.toLowerCase())) &&
                moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") >=
                  moment(this.selectedStartTableDate).format("YYYY/MM/DD") &&
                moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") <=
                  moment(this.selectedEndTableDate).format("YYYY/MM/DD") &&
                item.recni_slivovis.name === this.selectedRecenSliv
              );
            })
            .sort(
              (a, b) =>
                new Date(b.datum_na_izdadena_dozvola) -
                new Date(a.datum_na_izdadena_dozvola)
            );
          return totalData.length;
        } else if (
          this.selectedTable === "Дозволи за уредување на речни корита"
        ) {
          const totalData = this.coordinatesData.pesok_cakal_kamen
            .filter(item => {
              return (
                (item.ime_na_nositel_na_dozvola
                  .toLowerCase()
                  .includes(this.search.toLowerCase()) ||
                  item.broj_na_dozvola
                    .toLowerCase()
                    .includes(this.search.toLowerCase())) &&
                moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") >=
                  moment(this.selectedStartTableDate).format("YYYY/MM/DD") &&
                moment(item.datum_na_izdadena_dozvola).format("YYYY/MM/DD") <=
                  moment(this.selectedEndTableDate).format("YYYY/MM/DD")
              );
            })
            .sort(
              (a, b) =>
                new Date(b.datum_na_izdadena_dozvola) -
                new Date(a.datum_na_izdadena_dozvola)
            );
          return totalData.length;
        } else {
          return 0;
        }
      } else return 0;
    }
  },
  data() {
    return {
      icons: {
        koristenje_vodi: L.icon({
          iconUrl:
            "https://png.pngtree.com/png-vector/20190630/ourlarge/pngtree-water-tap-icon-for-your-project-png-image_1532082.jpg",
          iconSize: [20, 20]
        }),
        ispustanje_vodi: L.icon({
          iconUrl:
            "https://mwt-algerie.com/wp-content/uploads/2020/06/Sigle-EFFLUENT-%C3%A9puration-250x....png",
          iconSize: [20, 20]
        }),
        pesok_cakal_kamen: L.icon({
          iconUrl:
            "https://cdn.iconscout.com/icon/premium/png-256-thumb/sand-48-899717.png",
          iconSize: [25, 25]
        })
      },
      isLegendExpanded: false,
      lastCard: {
        options: {
          button: false,
          date: false,
          description: false,
          img: false,
          paragraphOn: true,
          subTitle: false,
          titleClickable: false
        }
      },
      mk: mk,
      al: al,
      en: en,
      mapOptions: {
        attribution:
          "© 2022 Designed and powered by Akrinum OÜ . All Rights Reserved",
        center: [41.589, 21.664],
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        zoom: 8
      },
      camera: null,
      clock: null,
      formatDate: "DD/MM/YYYY",
      mouseX: 0,
      mouseY: 0,
      particlesMesh: null,
      renderer: null,
      scene: null,
      search: "",
      selectedTable: "Дозволи за користење на води",
      selectedRecenSliv: "Вардар",
      selectedStartTableDate: "2020/12/01",
      selectedEndTableDate: "2022/03/01",
      selectedStartDate: "2020/12/01",
      selectedEndDate: "2022/03/01",
      typeFilter: coordinatesTypes.ALL,
      typeFilters: [
        {
          name: coordinatesTypes.ALL
        },
        {
          name: coordinatesTypes.KORISTENJE_VODI
        },
        {
          name: coordinatesTypes.ISPUSTANJE_VODI
        },
        {
          name: coordinatesTypes.PESOK_CAKAL
        },
        {
          name: coordinatesTypes.KONCESII
        },
        {
          name: coordinatesTypes.VODOSTOPANSKI_SOGLASNOSTI
        }
      ],
      typeFiltersAL: [
        {
          name: coordinatesTypesAL.ALL
        },
        {
          name: coordinatesTypesAL.KORISTENJE_VODI
        },
        {
          name: coordinatesTypesAL.ISPUSTANJE_VODI
        },
        {
          name: coordinatesTypesAL.PESOK_CAKAL
        },
        {
          name: coordinatesTypesAL.KONCESII
        },
        {
          name: coordinatesTypesAL.VODOSTOPANSKI_SOGLASNOSTI
        }
      ],
      typeFiltersEN: [
        {
          name: coordinatesTypesEN.ALL
        },
        {
          name: coordinatesTypesEN.KORISTENJE_VODI
        },
        {
          name: coordinatesTypesEN.ISPUSTANJE_VODI
        },
        {
          name: coordinatesTypesEN.PESOK_CAKAL
        },
        {
          name: coordinatesTypes.KONCESII
        },
        {
          name: coordinatesTypes.VODOSTOPANSKI_SOGLASNOSTI
        }
      ],
      sphere: null,
      pageIndex: 1,
      pageSize: 10
    };
  },
  methods: {
    ...mapActions([
      "setSelectedItem",
      "setSelectedStartDate",
      "setSelectedEndDate",
      "setSelectedCoordinatesType"
    ]),
    animateBook() {
      dat.GUI.toggleHide();
      const canvas = document.querySelector("canvas.webgl");
      this.scene = new THREE.Scene();
      const loader = new THREE.TextureLoader();

      //start image
      const star = require("@/assets/images/star.png");

      //images
      const edge = require("@/assets/images/vodna-kniga-edge.png");
      const long_white = require("@/assets/images/vodna-kniga-long-white.png");
      const front = require("@/assets/images/vodna-kniga-front.png");
      const short_white = require("@/assets/images/vodna-kniga-short-white.png");

      const urls = [long_white, edge, short_white, short_white, front, front];

      const materials = urls.map(url => {
        return new THREE.MeshLambertMaterial({
          map: loader.load(url)
        });
      });

      //Book
      const book = new THREE.BoxGeometry(1, 1.5, 0.2);

      //Add to Sphere
      this.sphere = new THREE.Mesh(book, materials);

      //Ambient Light of book
      const ambient = new THREE.AmbientLight(0x22222);
      this.scene.add(ambient);

      // Lights of the book
      const pointLight = new THREE.PointLight(0xffffff);
      pointLight.position.x = 0;
      pointLight.position.y = 0;
      pointLight.position.z = 6;
      this.scene.add(pointLight);

      //Mesh
      const particalGeometry = new THREE.BufferGeometry();
      const particalCnt = 500;
      const posArray = new Float32Array(particalCnt * 3);
      for (let i = 0; i < particalCnt * 2; i++) {
        posArray[i] = (Math.random() - 0.5) * (Math.random() * 30);
      }
      particalGeometry.setAttribute(
        "position",
        new THREE.BufferAttribute(posArray, 3)
      );
      const particlesMaterial = new THREE.PointsMaterial({
        size: 0.029,
        map: loader.load(star),
        transparent: true
      });

      //Sphere
      this.particlesMesh = new THREE.Points(
        particalGeometry,
        particlesMaterial
      );
      this.scene.add(this.sphere, this.particlesMesh);

      const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
      };

      window.addEventListener("resize", () => {
        // Update sizes
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;

        this.camera.aspect = sizes.width / sizes.height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(sizes.width, sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      });

      this.camera = new THREE.PerspectiveCamera(
        75,
        sizes.width / sizes.height,
        0.1,
        100
      );
      this.camera.position.x = 0;
      this.camera.position.y = 0;
      this.camera.position.z = 2;
      this.scene.add(this.camera);

      const controls = new OrbitControls(this.camera, canvas);
      controls.enableDamping = false;

      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: canvas
      });

      this.renderer.setSize(sizes.width, sizes.height);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      this.renderer.setClearColor(new THREE.Color("#000"), 1);

      document.addEventListener("mousemove", this.animateParticles);

      this.mouseX = 0;
      this.mouseY = 0;

      this.clock = new THREE.Clock();
      const elapsedTime = this.clock.getElapsedTime();

      this.sphere.rotation.y = 0.5 * elapsedTime;
      this.particlesMesh.rotation.y = -0.1 * elapsedTime;

      if (this.mouseX > 0) {
        this.particlesMesh.rotation.x = -this.mouseY * (elapsedTime * 0.00005);
        this.particlesMesh.rotation.y = this.mouseX * (elapsedTime * 0.00009);
      }

      this.renderer.render(this.scene, this.camera);

      this.reAnimateBook();
    },
    animateParticles(event) {
      this.mouseY = event.clientY;
      this.mouseX = event.clientX;
    },
    animteTopCard() {
      const content = CSSRulePlugin.getRule(".content:before");
      const h1 = document.querySelector("h1");
      const p = document.querySelector("p");
      const tl = gsap.timeline();

      tl.from(content, {
        delay: 0.5,
        duration: 4,
        cssRule: {
          scaleX: 0
        }
      });
      tl.to(
        h1,
        {
          duration: 2,
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          y: "30px"
        },
        "-=3.2"
      );
      tl.to(
        p,
        {
          duration: 2.5,
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          y: "21px"
        },
        "-=2"
      );
    },
    changeSelectedTable(tableName) {
      this.selectedTable = tableName;
    },
    changeTableLanguage() {
      const lang = {
        pagination: {
          goto: "до",
          itemsPerPage: " /страна",
          total: function total(_total) {
            return "Вкупно " + _total;
          }
        }
      };
      this.$veLocale.update(lang);
    },
    getFormatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getIcon(item) {
      if (item.name === coordinatesTypes.KORISTENJE_VODI) {
        if (item.namena_na_vodata_multis[0].namena_na_vodatas_id === 1) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/9R6_1646006634_R1.png",
            iconSize: [20, 20]
          });
        } else if (item.namena_na_vodata_multis[0].namena_na_vodatas_id === 2) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/7VI_1646006634_R2.png",
            iconSize: [20, 20]
          });
        } else if (item.namena_na_vodata_multis[0].namena_na_vodatas_id === 3) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/Xjq_1646006634_R3.png",
            iconSize: [20, 20]
          });
        } else if (item.namena_na_vodata_multis[0].namena_na_vodatas_id === 4) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/snu_1646006634_R4.png",
            iconSize: [20, 20]
          });
        } else if (item.namena_na_vodata_multis[0].namena_na_vodatas_id === 5) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/hug_1646006634_R5.png",
            iconSize: [20, 20]
          });
        } else if (item.namena_na_vodata_multis[0].namena_na_vodatas_id === 6) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/A4M_1646006634_R6.png",
            iconSize: [20, 20]
          });
        } else if (item.namena_na_vodata_multis[0].namena_na_vodatas_id === 7) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/t3Z_1646006634_R7.png",
            iconSize: [20, 20]
          });
        } else if (item.namena_na_vodata_multis[0].namena_na_vodatas_id === 8) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/ipH_1646006634_R8.png",
            iconSize: [20, 20]
          });
        } else if (item.namena_na_vodata_multis[0].namena_na_vodatas_id === 9) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/ymp_1646006634_R9.png",
            iconSize: [20, 20]
          });
        } else if (
          item.namena_na_vodata_multis[0].namena_na_vodatas_id === 10
        ) {
          return L.icon({
            iconUrl:
              "https://png.pngtree.com/png-vector/20190630/ourlarge/pngtree-water-tap-icon-for-your-project-png-image_1532082.jpg",
            iconSize: [20, 20]
          });
        } else {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/jIh_1646005238_1-removebg-preview.png",
            iconSize: [20, 20]
          });
        }
      } else if (item.name === coordinatesTypes.ISPUSTANJE_VODI) {
        if (item.vid_na_objektot_ispustanjes_id === 1) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/jIh_1646005238_1-removebg-preview.png",
            iconSize: ["auto", 20]
          });
        } else if (item.vid_na_objektot_ispustanjes_id === 2) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/rn9_1646005238_2-removebg-preview.png",
            iconSize: ["auto", 20]
          });
        } else if (item.vid_na_objektot_ispustanjes_id === 3) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/iTa_1646005238_3-removebg-preview.png",
            iconSize: ["auto", 20]
          });
        } else if (item.vid_na_objektot_ispustanjes_id === 4) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/L0e_1646005238_4-removebg-preview.png",
            iconSize: ["auto", 20]
          });
        } else if (item.vid_na_objektot_ispustanjes_id === 5) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/YNv_1646005238_5-removebg-preview.png",
            iconSize: ["auto", 20]
          });
        } else if (item.vid_na_objektot_ispustanjes_id === 6) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/xAQ_1646005238_6-removebg-preview.png",
            iconSize: ["auto", 20]
          });
        } else if (item.vid_na_objektot_ispustanjes_id === 7) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/6ms_1646005238_7-removebg-preview.png",
            iconSize: ["auto", 20]
          });
        } else if (item.vid_na_objektot_ispustanjes_id === 8) {
          return L.icon({
            iconUrl:
              "https://app.imgforce.com/images/user/q5J_1646005238_8-removebg-preview.png",
            iconSize: ["auto", 20]
          });
        } else {
          return L.icon({
            iconUrl:
              "https://mwt-algerie.com/wp-content/uploads/2020/06/Sigle-EFFLUENT-%C3%A9puration-250x....png",
            iconSize: ["auto", 20]
          });
        }
      } else if (
        item.name === coordinatesTypes.PESOK_CAKAL ||
        item.name === "Дозвола за вадење на песок, чакал и камен"
      ) {
        return this.icons.pesok_cakal_kamen;
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    },
    reAnimateBook() {
      const elapsedTime = this.clock.getElapsedTime();
      this.sphere.rotation.y = 0.5 * elapsedTime;
      this.sphere.rotation.x = 0.3 * elapsedTime;
      this.particlesMesh.rotation.y = -0.1 * elapsedTime;

      if (this.mouseX > 0) {
        this.particlesMesh.rotation.x = -this.mouseY * (elapsedTime * 0.00005);
        this.particlesMesh.rotation.y = this.mouseX * (elapsedTime * 0.00009);
      }

      this.renderer.render(this.scene, this.camera);
      window.requestAnimationFrame(this.reAnimateBook);
    },
    selectItem(key) {
      if (key === 0) {
        this.setSelectedItem(this.coordinatesData.koristenje_vodi);
        this.setSelectedCoordinatesType(coordinatesTypes.KORISTENJE_VODI);
      } else if (key === 1) {
        this.setSelectedItem(this.coordinatesData.ispustanje_vodi);
        this.setSelectedCoordinatesType(coordinatesTypes.ISPUSTANJE_VODI);
      } else if (key === 2) {
        this.setSelectedItem(this.coordinatesData.pesok_cakal_kamen);
        this.setSelectedCoordinatesType(coordinatesTypes.PESOK_CAKAL);
      }
    },
    toggleLegend() {
      this.isLegendExpanded = !this.isLegendExpanded;
      let selectedLegend = document.querySelector(".legend-popup");
      if (this.isLegendExpanded) {
        selectedLegend.style.opacity = "1";
        selectedLegend.style.animationName = "fadeUp";
        selectedLegend.style.zIndex = "10";
      } else {
        selectedLegend.style.opacity = "0";
        selectedLegend.style.animationName = "none";
        setTimeout(() => {
          selectedLegend.style.zIndex = "-1";
        }, 700);
      }
    }
  },
  mounted() {
    this.animateBook();
    this.animteTopCard();
    this.changeTableLanguage();
  }
};
</script>
