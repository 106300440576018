<template>
  <div class="flexi-mobile-navigation__wrapper">
    <slot name="burger" v-bind="{ isNavOpen, toggleBurger }">
      <div class="flexi-mobile-navigation__wrapper--navigation">
        <button
          class="burger__button"
          :class="{ active: isNavOpen }"
          @click="toggleBurger"
        >
          <span class="burger__button--bar" />
          <span class="burger__button--bar" />
          <span class="burger__button--bar" />
        </button>
      </div>
    </slot>
    <slot
      name="sidebar"
      v-bind="{
        navigation,
        isNavOpen,
        navigateToRoute,
        navigateToExternalPage,
        toggleBurger
      }"
    >
      <div class="flexi-mobile-navigation__wrapper--sidebar" v-if="isNavOpen">
        <div class="logo__wrapper">
          <img
            class="logo"
            src="https://app.imgforce.com/images/user/zrC_1622176244_logo-black-120.png"
            @click="navigateToRoute('/')"
          />
        </div>
        <ul class="sidebar__wrapper">
          <a
            class="sidebar__wrapper--item"
            v-for="(navigationItem, index) in navigation"
            :key="'item-' + index"
            @click="
              navigationItem.isLink
                ? navigateToExternalPage(navigationItem.href)
                : navigateToRoute(navigationItem.href)
            "
          >
            <slot name="sidebar-item" v-bind="{ navigationItem, navigation }">
              <li v-text="navigationItem.name" />
            </slot>
          </a>
        </ul>
        <div class="button__wrapper">
          <slot name="close-button" v-bind="{ navigation, toggleBurger }">
            <button class="button" @click="toggleBurger" v-text="'Back'" />
          </slot>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    navigation: {
      type: Array
    },
    isNavOpen: {
      type: Boolean
    },
    toggleBurger: {
      type: Function
    },
    navigateToExternalPage: {
      type: Function
    },
    navigateToRoute: {
      type: Function
    }
  }
};
</script>
