<template>
  <div id="app">
    <!-- Entering, reload site transition -->
    <transition name="fade" mode="out-in">
      <div class="app-wrapper" v-if="!loadingScreen" key="content">
        <!-- Header -->
        <flexi-header
          :inputData="inputData.navigationPages"
          :toggleTheme="toggleTheme"
          :changeLanguage="setLanguage"
        >
          <template v-slot:logo>
            <img
              :src="require('./assets/images/logo.png')"
              @click="goToRoute('/')"
            />
          </template>
          <template
            v-slot:navigation="{ inputData, toggleTheme, changeLanguage }"
          >
            <span
              v-if="!isMobile"
              class="flexi-header__wrapper--text"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'МЖСПП'
                  : language === 'АЛ' || language === 'AL'
                  ? 'MMJPH'
                  : 'МЖСПП'
              "
            />
            >
            <flexi-desktop-navigation :navigation="inputData">
              <!-- Desktop Version -->
              <template v-slot:navigation v-if="!isMobile">
                <template>
                  <a
                    class="flexi-desktop-navigation__wrapper--item"
                    v-for="(value, key) in inputData"
                    :key="key"
                    @click="navigate(value.href)"
                  >
                    <li v-text="value.name" />
                  </a>
                </template>
              </template>

              <!-- Mobile version -->
              <template v-slot:navigation v-else>
                <template>
                  <a class="flexi-desktop-navigation__wrapper--item">
                    <li
                      v-text="
                        language === 'MK' || language === 'МК'
                          ? 'МЖСПП - Водна Книга'
                          : language === 'АЛ' || language === 'AL'
                          ? 'MMJPH – Libri i ujit'
                          : 'МЖСПП - Водна Книга'
                      "
                    />
                  </a>
                  <div class="flexi-desktop-navigation__wrapper--bottom">
                    <a
                      v-for="(value, key) in inputData"
                      :key="key"
                      @click="navigate(value.href)"
                    >
                      <li v-text="value.name" />
                    </a>
                  </div>
                </template>
              </template>
            </flexi-desktop-navigation>
            <flexi-theme-switcher :toggleTheme="toggleTheme" />
            <flexi-language-switcher
              :languages="
                language === 'MK' || language === 'МК'
                  ? languagesItemsMk
                  : languagesItems
              "
              :changeLanguage="changeLanguage"
            />
          </template>
        </flexi-header>
        <!-- Router View + Transition -->
        <vue-page-transition name="overlay-up-down">
          <router-view />
        </vue-page-transition>
        <!-- Footer -->
        <flexi-footer :year="year">
          <template v-slot:footer>
            <div class="footer__wrapper--content">
              <div class="title" v-if="language === 'МК' || language === 'MK'">
                © {{ year }} Designed and powered by
                <a
                  class="action"
                  href="https://akrinum.com"
                  target="_blank"
                  v-text="'Akrinum OÜ'"
                />
                . All Rights Reserved.
              </div>
              <div
                class="title"
                v-else-if="language === 'АЛ' || language === 'AL'"
              >
                © {{ year }} Designed and powered by
                <a
                  class="action"
                  href="https://akrinum.com"
                  target="_blank"
                  v-text="'Akrinum OÜ'"
                />
                . All Rights Reserved. AL
              </div>
              <div class="footer--nav">
                <div
                  class="footer--nav__item"
                  v-for="(value, key) in inputData.homePage.sections[1].widgets"
                  :key="key"
                >
                  <a
                    :href="value.buttonLink"
                    target="_blank"
                    v-html="value.description"
                  />
                </div>
              </div>
            </div>
          </template>
        </flexi-footer>
      </div>
      <!-- Spinner on enterin or reload -->
      <flexi-splash v-else key="content2">
        <template v-slot:spinner>
          <semipolar-spinner
            :animation-duration="2000"
            :size="105"
            color="#ffffff"
            data-aos="fade-up"
            data-aos-delay="250"
            data-aos-duration="700"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"
          />
        </template>
      </flexi-splash>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import FlexiDesktopNavigation from "./components/FlexiDesktopNavigation.vue";
import FlexiHeader from "./components/FlexiHeader.vue";

export default {
  data() {
    return {
      languagesItems: [
        {
          title: "MK"
        },
        {
          title: "AL"
        }
      ],
      languagesItemsMk: [
        {
          title: "МК"
        },
        {
          title: "АЛ"
        }
      ]
    };
  },
  components: {
    FlexiDesktopNavigation,
    FlexiHeader
  },
  computed: {
    ...mapGetters(["inputData", "loadingScreen", "year", "theme", "language"])
  },
  methods: {
    ...mapActions([
      "fetchCMSData",
      "fetchAPIData",
      "setYear",
      "toggleTheme",
      "setLanguage"
    ]),
    navigate(link) {
      if (link.includes("http")) {
        window.open(link, "_blank").focus();
      } else {
        this.smoothScroll(link);
      }
    }
  },
  mounted() {
    this.fetchCMSData();
    this.fetchAPIData();
    this.setYear();
  }
};
</script>
<style lang="scss">
@import "assets/scss/application";
.overlay-left,
.overlay-right,
.overlay-top,
.overlay-bottom,
.overlay-up-down {
  z-index: 10003 !important;
}
</style>
