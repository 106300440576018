var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.loadingScreen)?_c('div',{key:"content",staticClass:"app-wrapper"},[_c('flexi-header',{attrs:{"inputData":_vm.inputData.navigationPages,"toggleTheme":_vm.toggleTheme,"changeLanguage":_vm.setLanguage},scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('img',{attrs:{"src":require('./assets/images/logo.png')},on:{"click":function($event){return _vm.goToRoute('/')}}})]},proxy:true},{key:"navigation",fn:function(ref){
var inputData = ref.inputData;
var toggleTheme = ref.toggleTheme;
var changeLanguage = ref.changeLanguage;
return [(!_vm.isMobile)?_c('span',{staticClass:"flexi-header__wrapper--text",domProps:{"textContent":_vm._s(
              _vm.language === 'MK' || _vm.language === 'МК'
                ? 'МЖСПП'
                : _vm.language === 'АЛ' || _vm.language === 'AL'
                ? 'MMJPH'
                : 'МЖСПП'
            )}}):_vm._e(),_vm._v(" > "),_c('flexi-desktop-navigation',{attrs:{"navigation":inputData},scopedSlots:_vm._u([(!_vm.isMobile)?{key:"navigation",fn:function(){return [_vm._l((inputData),function(value,key){return _c('a',{key:key,staticClass:"flexi-desktop-navigation__wrapper--item",on:{"click":function($event){return _vm.navigate(value.href)}}},[_c('li',{domProps:{"textContent":_vm._s(value.name)}})])})]},proxy:true}:{key:"navigation",fn:function(){return [[_c('a',{staticClass:"flexi-desktop-navigation__wrapper--item"},[_c('li',{domProps:{"textContent":_vm._s(
                      _vm.language === 'MK' || _vm.language === 'МК'
                        ? 'МЖСПП - Водна Книга'
                        : _vm.language === 'АЛ' || _vm.language === 'AL'
                        ? 'MMJPH – Libri i ujit'
                        : 'МЖСПП - Водна Книга'
                    )}})]),_c('div',{staticClass:"flexi-desktop-navigation__wrapper--bottom"},_vm._l((inputData),function(value,key){return _c('a',{key:key,on:{"click":function($event){return _vm.navigate(value.href)}}},[_c('li',{domProps:{"textContent":_vm._s(value.name)}})])}),0)]]},proxy:true}],null,true)}),_c('flexi-theme-switcher',{attrs:{"toggleTheme":toggleTheme}}),_c('flexi-language-switcher',{attrs:{"languages":_vm.language === 'MK' || _vm.language === 'МК'
                ? _vm.languagesItemsMk
                : _vm.languagesItems,"changeLanguage":changeLanguage}})]}}],null,false,2767120530)}),_c('vue-page-transition',{attrs:{"name":"overlay-up-down"}},[_c('router-view')],1),_c('flexi-footer',{attrs:{"year":_vm.year},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"footer__wrapper--content"},[(_vm.language === 'МК' || _vm.language === 'MK')?_c('div',{staticClass:"title"},[_vm._v(" © "+_vm._s(_vm.year)+" Designed and powered by "),_c('a',{staticClass:"action",attrs:{"href":"https://akrinum.com","target":"_blank"},domProps:{"textContent":_vm._s('Akrinum OÜ')}}),_vm._v(" . All Rights Reserved. ")]):(_vm.language === 'АЛ' || _vm.language === 'AL')?_c('div',{staticClass:"title"},[_vm._v(" © "+_vm._s(_vm.year)+" Designed and powered by "),_c('a',{staticClass:"action",attrs:{"href":"https://akrinum.com","target":"_blank"},domProps:{"textContent":_vm._s('Akrinum OÜ')}}),_vm._v(" . All Rights Reserved. AL ")]):_vm._e(),_c('div',{staticClass:"footer--nav"},_vm._l((_vm.inputData.homePage.sections[1].widgets),function(value,key){return _c('div',{key:key,staticClass:"footer--nav__item"},[_c('a',{attrs:{"href":value.buttonLink,"target":"_blank"},domProps:{"innerHTML":_vm._s(value.description)}})])}),0)])]},proxy:true}],null,false,2795466305)})],1):_c('flexi-splash',{key:"content2",scopedSlots:_vm._u([{key:"spinner",fn:function(){return [_c('semipolar-spinner',{attrs:{"animation-duration":2000,"size":105,"color":"#ffffff","data-aos":"fade-up","data-aos-delay":"250","data-aos-duration":"700","data-aos-easing":"ease-in-out","data-aos-mirror":"true","data-aos-once":"false","data-aos-anchor-placement":"top-center"}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }