<template>
  <div class="flexi-theme-switcher__wrapper">
    <label class="flexi-theme-switcher__wrapper--item">
      <slot name="switcher" v-bind="{ toggleTheme }">
        <input class="input__wrapper" type="checkbox" @click="toggleTheme" />
        <span class="slider__wrapper" />
      </slot>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    toggleTheme: {
      type: Function
    }
  }
};
</script>
