<template>
  <div class="flexi-language-switcher__wrapper">
    <slot name="language-switcher" v-bind="{ languages, changeLanguage }">
      <div
        class="flexi-language-switcher__wrapper--item"
        v-for="(language, index) in languages"
        :key="'item-' + index"
        @click="changeLanguage(language.title)"
      >
        <slot
          name="language-item"
          v-bind="{ language, languages, changeLanguage }"
        >
          <span class="item" v-text="language.title" />
        </slot>
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  props: {
    languages: {
      type: Array,
      default: () => {
        return [
          {
            title: "MK"
          },
          {
            title: "AL"
          },
          {
            title: "EN"
          }
        ];
      }
    },
    changeLanguage: {
      type: Function
    }
  }
};
</script>
