import Vue from "vue";
import App from "./App.vue";

// import custom components from flexi-vue framework
import "@schnapsterdog/flexi-vue";

// import router
import router from "./router";

// import vueX
import store from "./store";

// If we need to use some common stuff through the app, feel free to create files into mixins folder
import "./mixins";

// Vee Validate
import "./validators";

//V-Select
import vSelect from "vue-select";

Vue.component("v-select", vSelect);

// import Font Awesome Icon. Description/Usage given into the file
import "./animation/icons";

// Modernistic/Minimalist Epic Spinners. Description/usage given into the file
import "./animation/spinners";

// LowerCase, V-tooltip and custom directives. If u want to create new global directive, give it a shot into the file
import "./directives";

// Great Animation on Scroll Library. For usage, see into App.vue
import AOS from "aos";
import "aos/dist/aos.css";

//page transition
import "./animation/page-transition";

//vue meta
import VueMeta from "vue-meta";

Vue.use(VueMeta, {
  keyName: "metaInfo"
});

// Vue Social Sharing
import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

// Leaflet Maps
import { LMap, LMarker, LPopup, LTileLayer, LLayerGroup } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

Vue.component("l-map", LMap);
Vue.component("l-layer-group", LLayerGroup);
Vue.component("l-marker", LMarker);
Vue.component("l-popup", LPopup);
Vue.component("l-tile-layer", LTileLayer);

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.use(require("vue-moment"));

import "vue-easytable/libs/theme-default/index.css"; // import style

import {
  VeTable,
  VePagination,
  VeIcon,
  VeLoading,
  VeLocale
} from "vue-easytable"; // import library
import enUS from "vue-easytable/libs/locale/lang/en-US.js";

VeLocale.use(enUS);

Vue.use(VeTable);
Vue.use(VePagination);
Vue.use(VeIcon);
Vue.use(VeLoading);

Vue.prototype.$veLoading = VeLoading;
Vue.prototype.$veLocale = VeLocale;

Vue.config.productionTip = false;
// ignore ion icons components
Vue.config.ignoredElements = [/^ion-/];

new Vue({
  router,
  store,
  created() {
    AOS.init({});
  },
  render: h => h(App)
}).$mount("#app");
