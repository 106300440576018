const apiUrl = process.env.VUE_APP_API_URL;
const apiUrl2 = process.env.VUE_APP_API2_URL;

const token = process.env.VUE_APP_SECRET;
const tokenAl = process.env.VUE_APP_SECRET_AL;
const token2 = process.env.VUE_APP_API2_SECRET;

export default {
  fetchCMSData(language) {
    return new Promise(resolve => {
      var selectedToken;
      if (language === "MK" || language === "МК") {
        selectedToken = token;
      } else if (language === "AL" || language === "АЛ") {
        selectedToken = tokenAl;
      }
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener("readystatechange", function() {
        if (this.readyState === 4) {
          resolve(JSON.parse(this.responseText));
        }
      });

      xhr.open("POST", `${apiUrl}`);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.setRequestHeader("Authorization", `Bearer ${selectedToken}`);
      xhr.setRequestHeader("Accept", "*/*");
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
      xhr.setRequestHeader("cache-control", "no-cache");
      xhr.send();
    });
  },
  fetchAPIData() {
    return new Promise(resolve => {
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener("readystatechange", function() {
        if (this.readyState === 4) {
          resolve(JSON.parse(this.responseText));
        }
      });

      xhr.open("POST", `${apiUrl2}`);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.setRequestHeader("Authorization", `Bearer ${token2}`);
      xhr.setRequestHeader("Accept", "*/*");
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
      xhr.setRequestHeader("cache-control", "no-cache");
      xhr.send();
    });
  }
};
